/**
 * Implement Gatsby's Browser APIs in this file.
 * Fake comm
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import "@fontsource/poppins"
import "@fontsource/inter"
import { Script } from "gatsby"
import React from "react"
import { Redirect } from "./src/components/LanguageHelpers/Redirect"
export const wrapPageElement = ({ element }) => (
  <>
    <Redirect />
    <Script id="gtm-init">
      {`// Initialize the data layer for Google Tag Manager (this should mandatorily be done before the Cookie Solution is loaded)
      if(window !== "undefined"){
        window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "denied",
        wait_for_update: 2000 
    });
      }
    
    `}
    </Script>

    <Script src="https://www.googletagmanager.com/gtag/js?id=G-7NEKBY1W1K"></Script>
    <Script id="get-confing-g">
      {`
       if(window !== "undefined"){
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-7NEKBY1W1K'); 
      }
      `}
    </Script>

    <Script
      id="iub-cs"
      src="https://cdn.iubenda.com/cs/iubenda_cs.js"
      charset="UTF-8"
    ></Script>
    <Script type="text/javascript">{`
      
var _iub = _iub || [];
_iub.csConfiguration = {"ccpaAcknowledgeOnDisplay":true,"ccpaAcknowledgeOnLoad":true,"ccpaAppliesToEntireUSA":true,"consentOnContinuedBrowsing":false,"countryDetection":true,"enableCcpa":true,"enableLgpd":true,"floatingPreferencesButtonDisplay":"anchored-center-right","gdprAppliesGlobally":false,"invalidateConsentWithoutLog":true,"lang":"en-GB","perPurposeConsent":true,"siteId":2816567,"whitelabel":false,"cookiePolicyId":44041245, "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#000000","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","listPurposes":true,"position":"float-bottom-left","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#0073CE","rejectButtonDisplay":true,"textColor":"#000000" }};
`}</Script>
    {element}
  </>
)
